.skills {
    display: flex;
    align-items: center;
}

.skills .container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 100px;
}

.skills .text {
    width: 646px;
    flex-shrink: 0;
}

.skills .grid-wrapper {
    display: grid;
}

.skills .grid {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.skills .grid > div {
    width: 8vw;
    height: 8vw;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    background-origin: content-box;
    position: relative;
    top: 0;
    left: 0;
    /* transition: top 1.5s, left 1.5s, right 1.5s, bottom 1.5s, height 1.5s,
        width 1.5s; */
    max-width: 155px;
    max-height: 155px;
}

.skills .grid > div.fake-box {
    background-color: #ffffff;
    padding: 10px;
}

.skills .grid > div.fake-box div {
    z-index: -1;
}

.skills .grid div div {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    background-origin: content-box;
    padding: 10px;
    position: absolute;
    top: 0;
    left: 0;
    /* transition: top 1.5s, left 1.5s, right 1.5s, bottom 1.5s, height 1.5s,
        width 1.5s; */
    z-index: 1;
    box-shadow: 0 0 30px var(--box-shadow-color);
    border-radius: 10px;
}

.skills .stop-transitions {
    transition: none !important;
}

.skills .grid .active {
    grid-column-start: span 3;
    grid-row-start: span 3;
    max-width: 480px;
    max-height: 480px;
}

.skills .grid .large {
    height: 100%;
    width: 100%;
}

.skills .grid .hide {
    display: none;
}

@media screen and (max-width: 1500px) {
    .skills .container {
        gap: 5px;
    }
}

@media screen and (max-width: 1050px) {
    .skills h2 {
        text-align: center;
        margin-bottom: 25px;
    }
    .skills .container {
        flex-direction: column;
    }
    .skills .text {
        width: 100%;
    }

    .skills .grid > div {
        width: 17vw;
        height: 17vw;
    }
}
