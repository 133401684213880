.project {
    background-color: #ffffff;
    padding: 15px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 30px var(--box-shadow-color);
    width: 40%;
    max-width: 760px;
    flex-shrink: 0;
    scroll-snap-align: center;
}

.project:first-child {
    margin-left: 100%;
}

.project:last-child {
    position: relative;
}

/* Safari fix for margin-right not applying. */
.project:last-child::after {
    position: absolute;
    content: "";
    left: 100%;
    width: 1000%;
    height: 100%;
}

@media screen and (max-width: 1300px) {
    .project {
        width: 80%;
    }
}

.project p {
    margin-bottom: auto;
}

.project .technologies {
    margin-top: 15px;
}

.project .technologies img {
    height: 30px;
    width: auto;
    margin-right: 10px;
    margin-bottom: 15px;
}

.project .buttons {
    display: flex;
    gap: 15px;
}

.project .buttons a {
    width: 50%;
    height: 50px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
}

.project .buttons .github {
    border: 1px solid var(--text-color);
    color: var(--text-color);
    transition: background-color 0.4s, color 0.4s;
}

.project .buttons .github:hover {
    background-color: var(--text-color);
    color: #ffffff;
}

.project .buttons .demo {
    background-color: var(--accent-color);
    color: #ffffff;
    transition: background-color 0.4s;
    width: 100%;
}

.project .buttons .demo:hover {
    background-color: var(--accent-hover-color);
}
