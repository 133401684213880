:root {
    --text-color: #707070;
    --accent-color: #00a6fc;
    --accent-hover-color: #0091dc;
    --box-shadow-color: #ebebeb;
    --horizontal-space: 15px;
    --vertical-space: 150px;
    --title-space: 50px;
}

:root::before {
    content: url("images/skills/css-logo.png")
        url("images/skills/html-logo.png") url("images/skills/java-logo.png")
        url("images/skills/javascript-logo.png")
        url("images/skills/mysql-logo.png") url("images/skills/php-logo.png")
        url("images/skills/react-logo.png");
    visibility: hidden;
    position: absolute;
    left: -999em;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

body {
    font-size: 16px;
    font-family: Arial;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--text-color);
    font-weight: normal;
    margin-bottom: var(--title-space);
}

p {
    color: var(--text-color);
}

a {
    color: var(--text-color);
    transition: color 0.4s;
}

a:hover {
    color: var(--accent-hover-color);
}

.highlight {
    color: var(--accent-color);
}

.container {
    max-width: 1900px;
    margin: 0 auto;
}

section {
    padding: calc(var(--horizontal-space) * 2) var(--horizontal-space);
}

section {
    margin-bottom: var(--vertical-space);
}

h1,
h2 {
    font-size: 3.5em;
}

h3 {
    font-size: 2em;
}

@media screen and (max-width: 1050px) {
    h1,
    h2 {
        font-size: 2.5em;
    }
}

@media screen and (max-width: 700px) {
    h1,
    h2 {
        font-size: 2em;
    }

    h3 {
        font-size: 1.75em;
    }
}

@media screen and (max-width: 465px) {
    h1,
    h2 {
        font-size: 1.75em;
    }

    h3 {
        font-size: 1.5em;
    }
}

@media screen and (max-width: 410px) {
    h1,
    h2 {
        font-size: 1.5em;
    }

    h3 {
        font-size: 1.25em;
    }
}
