section.hero {
    min-height: min(calc(100svh - 50px), 1000px);
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

.hero .container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 100px;
}

.hero .text {
    width: 646px;
    flex-shrink: 0;
}

.hero .subtitle {
    display: block;
    font-size: 0.75em;
}

.hero .text .highlight {
    color: var(--accent-color);
    animation: shiny 10s ease-in-out infinite;
}

.hero .image {
    width: 100%;
    display: flex;
    justify-content: center;
}

.hero .image img {
    width: 100%;
    border-radius: 100%;
    object-fit: cover;
    aspect-ratio: 1 / 1;
    box-shadow: 0 0 30px var(--box-shadow-color);
    max-width: 650px;
}

.hero .button {
    display: block;
    width: 100%;
    background-color: var(--accent-color);
    color: #ffffff;
    transition: background-color 0.4s;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    text-decoration: none;
    font-size: 1.125em;
}

.hero .button:hover {
    background-color: var(--accent-hover-color);
}

.hero p {
    font-size: 1.125em;
    line-height: 175%;
    margin-bottom: 25px;
}

.hero .mobile-profile-picture {
    border-radius: 100%;
    width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    margin: 0 auto;
    margin-bottom: var(--title-space);
    max-width: 500px;
    display: none;
    box-shadow: 0 0 30px var(--box-shadow-color);
}

@media screen and (max-width: 1500px) {
    .hero .container {
        gap: 20px;
    }
}

@media screen and (max-width: 1050px) {
    .hero .mobile-profile-picture {
        display: block;
    }

    .hero .image {
        display: none;
    }

    .hero h1 {
        text-align: center;
    }

    .hero .text {
        width: 100%;
    }
}

@media screen and (max-width: 600px) {
    .hero .mobile-profile-picture {
        max-width: 300px;
    }
}
