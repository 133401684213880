footer {
    background-color: #ffffff;
    padding: 5px var(--horizontal-space);
    display: flex;
    align-items: center;
    box-shadow: 0 -5px 5px var(--box-shadow-color);
    height: 100px;
}

footer .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
}

footer .logo {
    height: 100%;
    position: absolute;
    top: 0;
}

footer .footer-text {
    width: 80%;
    display: flex;
    gap: 150px;
    align-items: center;
}

footer .icons {
    display: flex;
}

footer .icons img {
    height: 100%;
}

footer .icons a {
    max-height: 40px;
    text-align: center;
}

footer .icons a:last-of-type img {
    margin-right: 0;
}

footer .name-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--text-color);
}

footer .name-container .name {
    font-size: 1.25em;
}

footer ul {
    display: flex;
    list-style-type: none;
    gap: 100px;
    margin-left: 200px;
}

footer ul li a {
    color: var(--text-color);
    transition: color 0.4s;
    text-decoration: none;
}

footer ul li a:hover {
    color: var(--accent-color);
}

footer .copyright {
    font-size: 0.7em;
    position: absolute;
    bottom: 0;
    right: 0;
}

/* @media screen and (max-width: 1110px) {
    footer .footer-text,
    footer ul {
        gap: 100px;
    }
}

@media screen and (max-width: 910px) {
    footer .footer-text,
    footer ul {
        gap: 50px;
    }
} */

@media screen and (max-width: 770px) {
    footer {
        padding: 20px 0;
        height: 100%;
    }

    footer .container {
        flex-direction: column;
        gap: 20px;
    }

    footer .logo {
        position: relative;
        height: 100px;
    }

    footer .footer-text {
        flex-direction: column;
        gap: 20px;
    }

    footer ul {
        flex-direction: column;
        gap: 20px;
        align-items: center;
        margin-left: 0;
    }

    footer .icons {
        justify-content: center;
    }

    footer .copyright {
        position: relative;
        bottom: 0;
        right: 0;
    }
}
