nav {
    padding: 5px var(--horizontal-space);
    height: 50px;
    box-shadow: 0 5px 5px var(--box-shadow-color);
    position: relative;
    background-color: #ffffff;
}

nav .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

nav .background {
    background-color: #ffffff;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

nav .logo {
    position: absolute;
    top: 0;
    z-index: 1;
    height: 100%;
}

nav ul {
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    z-index: 1;
}

nav ul li {
    margin-right: 50px;
}

nav ul li:first-of-type {
    margin-left: 100px;
}

nav ul li:last-of-type {
    margin-right: 0;
    margin-left: auto;
}

nav ul li a {
    color: #707070;
    text-decoration: none;
    transition: color 0.4s;
}

nav ul li a:hover {
    color: var(--accent-color);
}

nav ul .button a {
    background-color: var(--accent-color);
    border-radius: 100px;
    width: 150px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    transition: background-color 0.4s;
}

nav ul .button:hover a {
    color: #ffffff;
    background-color: var(--accent-hover-color);
}

nav .person {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    align-items: center;
    z-index: 1;
}

nav .name {
    color: var(--accent-color);
    font-size: 1.25em;
}

nav .mobile-menu-icon {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    cursor: pointer;
    z-index: 4;
}

nav .mobile-menu-icon .bar {
    width: 30px;
    height: 3px;
    background-color: var(--text-color);
    transition: transform 0.4s, background-color 0.4s;
    border-radius: 10px;
}

nav .mobile-menu-icon:hover .bar {
    background-color: var(--accent-color);
}

nav.active .mobile-menu-icon {
    position: relative;
    right: 4px;
    top: -4px;
}

nav .mobile-menu-icon .bar:nth-child(2) {
    margin: 5px 0;
}

nav.active .mobile-menu-icon .bar:nth-child(1) {
    transform: rotate(-45deg) translate(0, 10px);
}

nav.active .mobile-menu-icon .bar:nth-child(2) {
    display: none;
}

nav.active .mobile-menu-icon .bar:nth-child(3) {
    transform: rotate(45deg) translate(8px, -2px);
}

@media screen and (max-width: 770px) {
    nav {
        box-shadow: none;
    }

    nav .logo {
        left: var(--horizontal-space);
    }

    nav .mobile-menu-icon {
        display: flex;
    }

    nav .container {
        justify-content: right;
    }

    nav ul {
        position: absolute;
        width: 100%;
        background-color: #ffffff;
        top: -170px;
        left: 0;
        flex-direction: column;
        box-shadow: 0 5px 5px var(--box-shadow-color);
        padding-bottom: 20px;
        transition: top 0.4s;
        z-index: 0;
    }

    nav.active ul {
        top: 50px;
    }

    nav ul li {
        height: 50px;
        margin: 0;
        width: 100%;
    }

    nav ul li:first-of-type {
        margin-left: 0;
    }

    nav ul li a {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    nav ul .button {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    nav ul .button a {
        width: calc(100% - 30px);
        height: 100%;
    }
}
