.journey {
    position: relative;
}

.journey h2 {
    margin-left: auto;
    margin-right: auto;
}

.journey.shorten {
    height: 125vh;
    overflow: hidden;
}

.journey .shortener {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 20vh;
    background: linear-gradient(
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.5) 20%,
        rgba(255, 255, 255, 1) 100%
    );
    width: 100%;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.journey .shortener .button {
    display: block;
    width: 300px;
    background-color: var(--accent-color);
    color: #ffffff;
    transition: background-color 0.4s;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 100px;
    text-decoration: none;
    font-size: 1.125em;
    outline: none;
    margin: 0 auto;
    z-index: 2;
    cursor: pointer;
}

.journey .shortener .button:hover {
    background-color: var(--accent-hover-color);
}

.journey h2 {
    text-align: center;
}
