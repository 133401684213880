.portfolio {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
}

.portfolio h2 {
    text-align: center;
    margin-bottom: 0;
    padding: 0 var(--horizontal-space);
}

.portfolio h3 {
    margin-bottom: 0;
}

.portfolio .buttons {
    display: flex;
    gap: 15px;
    justify-content: left;
    padding-left: 30px;
}

.portfolio .left-arrow,
.portfolio .right-arrow {
    width: 30px;
    height: calc(100% - (var(--title-space) * 2));
    box-shadow: 0 0 30px var(--box-shadow-color);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.15em;
    cursor: pointer;
    transition: background-color 0.4s, opacity 0.4s;
    position: absolute;
    top: var(--title-space);
    z-index: 3;
    background-color: #ffffff;
    opacity: 0;
}

.portfolio :hover .left-arrow,
.portfolio :hover .right-arrow {
    opacity: 1;
}

.portfolio .left-arrow {
    left: 0;
}

.portfolio .right-arrow {
    right: 0;
}

.portfolio .grid {
    position: relative;
}

.portfolio .grid .container {
    overflow-x: scroll;
    display: flex;
    gap: 5%;
    scroll-snap-type: x mandatory;
    padding-top: var(--title-space);
    padding-bottom: var(--title-space);
    margin-bottom: calc(var(--vertical-space) - var(--title-space));
}

@media screen and (max-width: 1100px) {
    .portfolio .left-arrow,
    .portfolio .right-arrow {
        display: none;
    }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.portfolio .grid .container::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.portfolio .grid .container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

@media screen and (max-width: 850px) {
    .portfolio .grid {
        grid-template-columns: 1fr;
    }
}
