.contact-form {
    padding-bottom: 50px;
    display: flex;
    align-items: center;
}

.contact-form h2 {
    text-align: center;
    margin-bottom: 40px;
}

.contact-form form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-form .input-group {
    width: 100%;
    max-width: 950px;
    margin-bottom: 50px;
}

.contact-form input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    background-color: #ffffff;
    color: var(--text-color);
    height: 70px;
    font-size: 1em;
    border: none;
    outline: none;
    -webkit-box-shadow: 0 0 30px var(--box-shadow-color);
    box-shadow: 0 0 30px var(--box-shadow-color);
    padding: 0 30px;
    -webkit-border-radius: 15px;
    border-radius: 15px;
}

.contact-form input:focus,
.contact-form textarea:focus {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: var(--accent-color) solid 2px;
}

.contact-form label {
    margin-left: 30px;
    margin-bottom: 10px;
    display: block;
}

.contact-form textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    resize: none;
    width: 100%;
    height: 105px;
    border: none;
    outline: none;
    font-size: 1em;
    font-family: Arial;
    padding-left: 30px;
    border-radius: 15px;
    padding-top: 24px;
    box-shadow: 0 0 30px var(--box-shadow-color);
    background-color: #ffffff;
    color: var(--text-color);
}

.contact-form button {
    width: 100%;
    background-color: var(--accent-color);
    color: #ffffff;
    max-width: 950px;
    height: 50px;
    border: none;
    outline: none;
    border-radius: 100px;
    font-size: 1em;
    transition: background-color 0.4s, width 0.4s;
    cursor: pointer;
}

.contact-form button:hover {
    background-color: var(--accent-hover-color);
}

.contact-form button.loading {
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ebebeb;
}

.contact-form button.loading:hover {
    background-color: #ebebeb;
}

.contact-form button.loading span {
    display: none;
}

.contact-form .loader {
    border: 5px solid #ebebeb;
    border-radius: 50%;
    border-top: 5px solid var(--accent-color);
    width: 50px;
    height: 50px;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
    display: none;
}

.contact-form button.loading .loader {
    display: block;
}

.contact-form button.loading.success .loader {
    display: none;
}

.contact-form button.loading.success {
    background-color: var(--accent-color);
    background-image: url("../../images/icons/checkmark-icon.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    background-origin: content-box;
    padding: 10px;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
